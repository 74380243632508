<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>
        Top funded
        <small>Active profit of funded accounts</small>
      </b-card-title>

      <div class="d-flex flex-wrap">
        <account-types-switches
          v-model="form.accountTypes"
          class="mt-1"
          @input="loadData"
        />
        <platforms-switches
          v-model="form.platforms"
          class="mt-1"
          @input="loadData"
        />
        <country-select
          v-model="form.country"
          class="width-200 mt-1"
          @input="loadData"
        />
      </div>
      <div class="mt-1">
        <h5>User tags
          <b-form-checkbox
            v-model="form.excludeUserTags"
            class="ml-1 d-inline"
            @input="loadData"
          >
            Exclude results with user tags
          </b-form-checkbox>
        </h5>
        <tag-tree-select
          v-model="form.userTags"
          class="mt-50 half-width"
          @input="loadData"
        />
      </div>
      <div class="mt-1">
        <h5>Account tags
          <b-form-checkbox
            v-model="form.excludeAccountTags"
            class="ml-1 d-inline"
            @input="loadData"
          >
            Exclude results with account tags
          </b-form-checkbox>
        </h5>
        <tag-tree-select
          v-model="form.accountTags"
          class="mt-50 half-width"
          @input="loadData"
        />
      </div>
    </b-card-header>

    <b-overlay :show="loading">
      <b-card-body>
        <div
          v-for="(item, key) in fundedList"
          :key="key"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside v-if="!isMobile">
              <b-avatar
                rounded
                size="42"
                variant="light-info"
              >
                <b-img
                  v-if="item.user.profile_image"
                  class="w-100"
                  :src="getProfileImage(item)"
                />
                <feather-icon
                  v-else
                  icon="UserIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                <b-link :to="{name: 'user-detail', params: {id: item.user.id}}">
                  {{ item.user.firstName }} {{ item.user.lastName }}
                </b-link>
                |
                <b-link :to="{name: 'account-detail', params: {id: item.account_id}}">
                  {{ item.account_id }}
                </b-link>

              </h6>
              <small>{{ item.user.email }}</small>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder mr-2"
          >
            {{ formatCurrency(item.balance - item.deposit, item.currency.label || 'usd') }}
          </div>
        </div>
        <b-row
          class="text-center"
          align-v="center"
        >
          <b-col>
            <b-button
              variant="outline-primary"
              class="btn-icon mb-auto"
              @click="showAll = !showAll"
            >
              <feather-icon
                v-if="!showAll"
                icon="PlusIcon"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BOverlay,
  BLink,
  VBTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'
import StatisticsApi from '@/api/statistics'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import PlatformsSwitches from '@/components/ui/switches/PlatformsSwitches.vue'
import TagTreeSelect from '@/components/ui/tables/TagTreeSelect.vue'
import CountrySelect from '@/components/ui/forms/CountrySelect.vue'
import { DEFAULT_ACCOUNT_TYPES_SWITCHER, DEFAULT_PLATFORMS_SWITCHER } from '@/enums/accounts'

export default {
  components: {
    CountrySelect,
    BFormCheckbox,
    TagTreeSelect,
    AccountTypesSwitches,
    PlatformsSwitches,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BRow,
    BCol,
    BImg,
    BButton,
    BLink,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      showAll: false,
      topFunded: [],

      form: {
        accountTypes: DEFAULT_ACCOUNT_TYPES_SWITCHER.map(item => item.value),
        platforms: DEFAULT_PLATFORMS_SWITCHER,
        userTags: [],
        accountTags: [],
        excludeAccountTags: false,
        excludeUserTags: false,
        country: null,
      },
    }
  },
  computed: {
    ...get('app', ['isMobile']),
    fundedList() {
      return this.showAll ? this.topFunded : this.topFunded.slice(0, 10)
    },
  },
  watch: {
    types() {
      this.loadData()
    },
    platforms() {
      this.loadData()
    },
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    formatCurrency,
    getProfileImage(item) {
      return `${process.env.VUE_APP_API_URL}users/${item.id}/avatar/${item.profile_image}`
    },
    loadData() {
      this.loading = true
      StatisticsApi.getTopFunded(this.form)
        .then(response => {
          this.topFunded = response
          this.loading = false
        })
    },
  },
}
</script>
