<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        Profit VS withdrawals
      </b-card-title>
      <div class="d-flex flex-nowrap align-items-center">
        <account-types-switches
          v-model="types"
        />
        <platforms-switches v-model="platforms" />

        <b-form-group class="mt-1 mx-1">
          <h5>Date range</h5>
          <div class="d-flex">
            <flat-pickr
              v-model="dateRange"
              class="form-control"
              :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
            />

            <b-button
              variant="outline-primary"
              :disabled="dateRange == null"
              @click.prevent="onSearch"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-card-header>
    <b-overlay :show="loading">
      <b-card-body v-if="profitsVsWithdrawals">
        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart(Object.keys(profitsVsWithdrawals.profits_weekly))"
          :series="seriesWeekly"
        />
        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart(Object.keys(profitsVsWithdrawals.profits_daily))"
          :series="seriesDaily"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import { get } from 'vuex-pathify'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import PrimeStatisticsAPI from '@/api/prime-statistics'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import PlatformsSwitches from '@/components/ui/switches/PlatformsSwitches.vue'
import { DEFAULT_FILTER_ACCOUNTS_TYPES } from '@/enums/accounts'

export default {
  components: {
    PlatformsSwitches,
    BFormGroup,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AccountTypesSwitches,
    VueApexCharts,
    flatPickr,
    BOverlay,
  },
  data() {
    return {
      platforms: ['MT5', 'MT4'],
      types: DEFAULT_FILTER_ACCOUNTS_TYPES,
      profitsVsWithdrawals: null,
      dateRange: null,
      loading: false,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    seriesWeekly() {
      return [
        {
          name: 'Profit',
          type: 'column',
          data: Object.values(this.profitsVsWithdrawals.profits_weekly),
        },
        {
          name: 'Withdrawals',
          type: 'column',
          data: Object.values(this.profitsVsWithdrawals.withdrawals_weekly),
        },
      ]
    },
    seriesDaily() {
      return [
        {
          name: 'Profit',
          type: 'column',
          data: Object.values(this.profitsVsWithdrawals.profits_daily),
        },
        {
          name: 'Withdrawals',
          type: 'column',
          data: Object.values(this.profitsVsWithdrawals.withdrawals_daily),
        },
      ]
    },
  },
  beforeMount() {
    const now = new Date()
    const back28 = new Date()

    back28.setDate(back28.getDate() - 28)

    this.dateRange = `${back28.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')

      PrimeStatisticsAPI.getProfitsVsWithdrawals(this.types, this.platforms, from, to)
        .then(response => {
          this.profitsVsWithdrawals = response
          this.loading = false
        })
    },
    onSearch() {
      this.isLoading = true
      const date = this.dateRange.split(' to ')
      this.from = date[0].concat(' 00:00:00')
      this.to = date[1].concat(' 23:59:59')
      this.load()
    },
    balanceChart(labels) {
      return {
        chart: {
          toolbar: {
            show: true,
            offsetY: -20,
          },
        },
        stroke: {
          curve: 'straight',
        },
        colors: [$themeColors.success, $themeColors.primary, $themeColors.info],
        markers: {
          size: 2,
          colors: 'grey',
          hover: {
            size: 6,
          },
        },
        labels,
        xaxis: {
          type: 'string',
          labels: {
            hideOverlappingLabels: true,
            trim: false,
            style: {
              colors: '#b9b9c3',
            },
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: [{
          labels: {
            style: {
              colors: '#b9b9c3',
            },
          },
        }],
        tooltip: {
          x: { show: true },
          y: { show: true },
        },
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
</style>
