<template>
  <b-overlay
    :show="status === 'loading'"
    variant="transparent"
    blur="2px"
    rounded="sm"
  >
    <treeselect
      :value="value"
      :multiple="true"
      value-consists-of="LEAF_PRIORITY"
      :options="tagOptions"
      @input="value => $emit('input', value)"
    />
  </b-overlay>
</template>
<script>
import { BOverlay } from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { dispatch, get } from 'vuex-pathify'

export default {
  components: {
    Treeselect,
    BOverlay,
  },
  props: ['value'],
  computed: {
    ...get('tagsList', ['data', 'status']),
    tagOptions() {
      return this.data ? this.data.map(item => ({ id: item.id, label: item.name })) : []
    },
  },
  beforeCreate() {
    dispatch('tagsList/changeLimit', { limit: 500 })
    dispatch('tagsList/loadAll')
  },
}
</script>
