<template>
  <div>
    <!--    LEFT -->
    <b-row class="match-height">
      <b-col
        xl="7"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <!--   withdrawal chart -->
            <withdrawal-chart />
          </b-col>
          <b-col cols="12">
            <!--    failed funded chart-->
            <funded-fails-chart />
          </b-col>
          <b-col cols="12">
            <!--    funded profits chart-->
            <funded-profits-chart />
          </b-col>

          <b-col cols="12">
            <!--    profits vs withdrawals chart-->
            <profits-vs-withdrawals-chart />
          </b-col>
        </b-row>
      </b-col>

      <!--      RIGHT -->
      <b-col
        xl="5"
        sm="12"
      >
        <b-row>
          <b-col cols="12">
            <top-funded />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import WithdrawalChart from '@/components/page/statistics/WithdrawalChart.vue'
import TopFunded from '@/components/page/statistics/TopFunded.vue'
import FundedFailsChart from '@/views/prime-statistics/components/FundedFailsChart.vue'
import FundedProfitsChart from '@/views/prime-statistics/components/FundedProfitsChart.vue'
import ProfitsVsWithdrawalsChart from '@/views/prime-statistics/components/ProfitsVsWithdrawalsChart.vue'

export default {
  components: {
    ProfitsVsWithdrawalsChart,
    FundedProfitsChart,
    FundedFailsChart,
    TopFunded,
    WithdrawalChart,
    BRow,
    BCol,
  },

}
</script>
