import axiosInstance from '@/plugins/axios'

export default {
  getFundedFailed(accountTypes, from, to) {
    const url = 'admin/prime-statistics/funded-failed'

    return axiosInstance.get(url, { params: { filters: { accountTypes, from, to } } })
      .then(response => response.data)
  },

  getFundedProfitsByDays(accountTypes, from, to) {
    const url = 'admin/prime-statistics/funded-profits'

    return axiosInstance.get(url, { params: { filters: { accountTypes, from, to } } })
      .then(response => response.data)
  },

  getProfitsVsWithdrawals(accountTypes, platforms, from = null, to = null) {
    const url = 'admin/prime-statistics/profits-vs-withdrawals'

    return axiosInstance.get(url, {
      params: {
        filters: {
          accountTypes, platforms, from, to,
        },
      },
    })
      .then(response => response.data)
  },
}
