<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        Funded profits
      </b-card-title>
      <div class="d-flex flex-nowrap align-items-center">
        <account-types-switches
          v-model="types"
        />

        <b-form-group class="mt-1 mx-1">
          <h5>Date range</h5>
          <div class="d-flex">
            <flat-pickr
              v-model="dateRange"
              class="form-control"
              :config="{ wrap: true, mode: 'range', dateFormat: 'Y-m-d'}"
            />

            <b-button
              variant="outline-primary"
              :disabled="dateRange == null"
              @click.prevent="onSearch"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-card-header>
    <b-overlay :show="loading">
      <b-card-body v-if="profits">
        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series_profits"
        />
        <vue-apex-charts
          ref="chart"
          height="450px"
          :options="balanceChart.chartOptions"
          :series="balanceChart.series_loss"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BFormGroup, BButton, BOverlay,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import { get } from 'vuex-pathify'
import PrimeStatisticsAPI from '@/api/prime-statistics'
import AccountTypesSwitches from '@/components/ui/switches/AccountTypesSwitches.vue'
import { formatFloat } from '@/plugins/formaters'
import { DEFAULT_FILTER_ACCOUNTS_TYPES } from '@/enums/accounts'

export default {
  components: {
    BFormGroup,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    AccountTypesSwitches,
    VueApexCharts,
    flatPickr,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      profits: null,
      dateRange: null,
      types: DEFAULT_FILTER_ACCOUNTS_TYPES,
    }
  },
  computed: {
    ...get('appConfig@layout', ['skin']),
    balanceChart() {
      return {
        series_profits: [
          {
            name: 'MT4 profit',
            type: 'column',
            data: this.profits.map(item => formatFloat(item.profit_mt4)),
          },
          {
            name: 'MT5 profit',
            type: 'column',
            data: this.profits.map(item => formatFloat(item.profit_mt5)),
          },
          {
            name: 'Total profit',
            type: 'line',
            data: this.profits.map(item => formatFloat(Number(item.profit_mt5) + Number(item.profit_mt4))),
          },
        ],
        series_loss: [
          {
            name: 'MT4 loss',
            type: 'column',
            data: this.profits.map(item => formatFloat(item.loss_mt4)),
          },
          {
            name: 'MT5 loss',
            type: 'column',
            data: this.profits.map(item => formatFloat(item.loss_mt5)),
          },
          {
            name: 'Total loss',
            type: 'line',
            data: this.profits.map(item => formatFloat(Number(item.loss_mt5) + Number(item.loss_mt4))),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
              offsetY: -20,
            },
          },
          stroke: {
            curve: 'straight',
          },
          colors: [$themeColors.success, $themeColors.primary, $themeColors.info],
          markers: {
            size: 2,
            colors: 'grey',
            hover: {
              size: 6,
            },
          },
          labels: this.profits.map(item => item.date),
          xaxis: {
            type: 'datetime',
            labels: {
              hideOverlappingLabels: true,
              trim: false,
              style: {
                colors: '#b9b9c3',
              },
            },
            axisBorder: {
              show: false,
            },
          },
          yaxis: [{
            labels: {
              style: {
                colors: '#b9b9c3',
              },
            },
          }],
          tooltip: {
            x: { show: true },
            y: { show: true },
          },
        },
      }
    },
  },
  watch: {
    types() {
      this.load()
    },
  },
  beforeMount() {
    const now = new Date()
    const back7 = new Date()

    back7.setDate(back7.getDate() - 7)

    this.dateRange = `${back7.toISOString().slice(0, 10)} to ${now.toISOString().slice(0, 10)}`
    this.load()
  },
  methods: {
    formatFloat,
    load() {
      this.loading = true
      const date = this.dateRange.split(' to ')
      const from = date[0].concat(' 00:00:00')
      const to = date[1].concat(' 23:59:59')
      PrimeStatisticsAPI.getFundedProfitsByDays(this.types, from, to)
        .then(response => {
          this.profits = response
          this.loading = false
        })
    },
    onSearch() {
      this.isLoading = true
      const date = this.dateRange.split(' to ')
      this.from = date[0].concat(' 00:00:00')
      this.to = date[1].concat(' 23:59:59')
      this.load()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker";
</style>
